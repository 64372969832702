import { Component, Input, OnChanges } from '@angular/core';
import OrderDetails from '../../../../core/models/order.details';
import { TicketStatus } from '../../../../core/models/ticket.status';

interface TicketPlace {
  place: string;
  link: string | null;
}
@Component({
  selector: 'order-detail-headband',
  templateUrl: './order-detail-headband.component.html',
  styleUrls: ['./order-detail-headband.component.css'],
})
export class OrderDetailHeadbandComponent implements OnChanges {
  @Input() public order: OrderDetails | null = null;

  isLoading = false;
  @Input() set loading(value: boolean) {
    this.isLoading = value;
  }

  places: TicketPlace[] = [];
  dates: string[] = [];

  constructor() {}

  ngOnChanges() {
    const tickets = this.order
      ? this.order.status == TicketStatus.CANCELLED
        ? this.order.cancelledTickets
        : this.order.tickets
      : [];

    tickets.forEach((ticket: any) => {
      const start_on = ticket.ticketType.start_on ?? this.order?.event.startAt;
      const _place = ticket.ticketType.place ?? this.order?.event.place;
      const placeLink = ticket.ticketType.link ?? this.order?.event.link;

      if (start_on && !this.dates.find((date) => date == start_on)) {
        this.dates.push(start_on);
      }

      this.dates.sort();

      if (_place && !this.places.find((place) => place.place == _place)) {
        this.places.push({ place: _place, link: placeLink });
      }
    });
  }
}
